import { HttpError } from '@tomra/client-side-http-client'
import { logError } from '@tomra/datadog-browser-logging'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CheckmarkIcon, Modal, TermsAndConditions } from '../../components/shared'
import { auth } from '../../firebase'
import { THEME } from '../../lib'
import { fetchCharities, submitCharity } from '../../services'
import { deleteDraft, getOrCreateDraft, updateDraft } from '../../services/firestore.service'
import { CharityForm } from './common/CharityForm'
import { CharityFormSkeleton } from './common/CharityFormSkeleton'
import { DonationPartnerForm } from './common/DonationPartnerForm'

const { displayName, stateShort } = THEME

export const CreateCharityPage = () => {
  const adminId = useMemo(() => auth.currentUser!.uid, [])
  const navigate = useNavigate()
  const [savedDraft, setSavedDraft] = useState<Draft>()
  const [submitRequestStatus, setSubmitRequestStatus] = useState<RequestStatus>('initial')
  const [submitError, setSubmitError] = useState('')
  const [submittedCharityId, setSubmittedCharityId] = useState<string>()

  const shouldShowDonationPartnerForm = stateShort === 'VIC' && !savedDraft?.charity?.organization?.organizationAbn

  useEffect(() => {
    const f = fetchCharities()

    f.run()
      .then(charities => {
        if (charities.length > 0) {
          return navigate(`/charity/${charities[0].id}`)
        }

        getOrCreateDraft(adminId, null).then(data => {
          setSavedDraft(data)
        })
      })
      .catch(error => {
        if ((error as HttpError).name !== 'AbortError') {
          logError(new Error('Failed to fetch campaigns'), error)
          throw error
        }
      })

    return () => f.abort()
  }, [adminId])

  const createCharity = async (charity: Charity) => {
    try {
      setSubmitError('')
      setSubmitRequestStatus('loading')

      await submitCharity({
        id: charity.id,
        organization: charity.organization,
        bankingData: charity.bankingData,
        primaryContact: charity.primaryContact,
        secondaryContact: charity.secondaryContact || null,
        logoUrl:
          charity.logoUrl === null && savedDraft?.OrganizationLogo
            ? savedDraft.OrganizationLogo.filename
            : (charity.logoUrl as string)
      })

      await deleteDraft(adminId)

      setSubmittedCharityId(charity.id)
      setSubmitRequestStatus('success')
    } catch (error: any) {
      if (error.status === 412) {
        setSubmitRequestStatus('preConditionFailed')
        setSubmitError(error?.body?.detail)
      } else {
        setSubmitRequestStatus('failed')
        logError(new Error('Failed to create new charity'), error)
      }
    }
  }

  async function onAcceptedTosChanged(hasAcceptedTos: boolean) {
    setSavedDraft({ ...savedDraft, hasAcceptedTos, updatedAt: new Date().toISOString() } as Draft)
    await updateDraft(adminId, { updatedAt: new Date().toISOString(), hasAcceptedTos })
  }

  async function confirmDonationPartnerDetails(donationPartnerDetails: DonationPartnerDetails) {
    const { donationPartnerId, legalName, businessRegistrationNumber } = donationPartnerDetails

    const newState = {
      ...savedDraft,
      charity: {
        ...savedDraft?.charity,
        organization: {
          ...savedDraft?.charity?.organization,
          externalId: donationPartnerId,
          organizationAbn: businessRegistrationNumber,
          name: legalName
        }
      },
      updatedAt: new Date().toISOString()
    } as Draft

    setSavedDraft(newState)
    await updateDraft(adminId, newState)
  }

  return shouldShowDonationPartnerForm && savedDraft ? (
    <DonationPartnerForm onConfirmDetails={confirmDonationPartnerDetails} />
  ) : savedDraft ? (
    <>
      <CharityForm
        original={null}
        savedDraft={savedDraft as Draft}
        onSubmit={createCharity}
        submitRequestStatus={submitRequestStatus}
        submitError={submitError}
        formHeading={
          <>
            <h1 className="text-2xl mb-4">Fundraising through the {displayName} app</h1>
            <div className="prose-sm">
              <p>
                Before you can be part of the {displayName} app charity program, we need to know a bit about your
                charity. Fill out the form below and someone from our team will review your application.
              </p>
              <p>
                All changes are automatically saved as draft. You can come back and complete your application at any
                time.
              </p>
            </div>
          </>
        }
        acceptTerms={
          <TermsAndConditions
            hasAcceptedTos={savedDraft?.hasAcceptedTos || false}
            onHasAcceptedTosChange={onAcceptedTosChanged}
          />
        }
      />

      <Modal isOpen={submitRequestStatus === 'success'}>
        <div className="dialog">
          <div className="card flex flex-col items-center p-6 space-y-4">
            <CheckmarkIcon width="4rem" height="4rem" color="rgb(var(--tomra-mint-green-dark))" />
            <div className="text-center">
              <h1 className="text-2xl">Almost there!</h1>
              <p>Now let&apos;s add a campaign.</p>
            </div>
            <button className="btn primary" onClick={() => navigate(`/charity/${submittedCharityId}/campaign`)}>
              Continue
            </button>
          </div>
        </div>
      </Modal>
    </>
  ) : (
    <CharityFormSkeleton />
  )
}
