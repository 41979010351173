import { HttpError } from '@tomra/client-side-http-client'
import { API_HOST, authenticatedFetchData, authenticatedFetchRaw } from '../lib'

export async function fetchCharitySummary() {
  const charities = await authenticatedFetchData(`${API_HOST}/charities/v1/charity`).run()

  if (charities.length === 0) {
    return null
  }

  return charities[0] as CharitySummary
}

export async function fetchCharity(charityId: string) {
  const response = await authenticatedFetchRaw(`${API_HOST}/charities/v1/charity/${charityId}`).run()

  const {
    data: charity,
    meta: { attachments }
  } = await response.json()

  return { charity, attachments } as { charity: Charity; attachments: Attachment[] }
}

export async function fetchPendingChangesForCharity(charityId: string) {
  try {
    const response = await authenticatedFetchRaw(`${API_HOST}/charities/v1/pending-charities/${charityId}`).run()

    const {
      data: changes,
      meta: { attachments }
    } = await response.json()

    return { changes, attachments } as { changes: Charity; attachments: Attachment[] }
  } catch (error) {
    if ((error as HttpError).status === 404) {
      return null
    }

    throw error
  }
}

export async function uploadFile(file: File | Blob, charityId: string, documentType: CharityDocument) {
  return authenticatedFetchData(`${API_HOST}/charities/v2/charity/${charityId}/links/${documentType}`)
    .run()
    .then(({ url, headers }) =>
      fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
          ...headers
        },
        body: file
      }).then(response => {
        if (!response.ok) {
          throw new Error('Failed to upload file')
        }
      })
    )
}

export function fetchCharities() {
  return authenticatedFetchData(`${API_HOST}/charities/v1/charity`)
}

export function fetchOrgNameByAbn(abn: string) {
  return authenticatedFetchData(`${API_HOST}/charities/v1/abn/${abn}/name`).run()
}

export function submitCharity(charity: Charity) {
  return authenticatedFetchData(`${API_HOST}/charities/v1/charity`, {
    method: 'POST',
    body: JSON.stringify({
      ...charity,
      secondaryContact: charity.secondaryContact?.firstName ? charity.secondaryContact : null
    })
  }).run()
}

export function updateCharity(charity: Charity) {
  return authenticatedFetchData(`${API_HOST}/charities/v1/charity/${charity.id}`, {
    method: 'PATCH',
    body: JSON.stringify(charity)
  }).run()
}

export function getDonationPartnerDetails(donationPartnerId: string) {
  return authenticatedFetchData(`${API_HOST}/charities/v1/donation-partner/${donationPartnerId}`, {
    method: 'GET'
  }).run()
}
