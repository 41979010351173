import { attachmentsTypes } from './charity'

export function initUiFields(charity: Charity | null, savedDraft?: Draft, attachments?: Attachment[]) {
  if (charity) {
    const charityWithDraft = { ...charity, ...savedDraft?.charity }

    return {
      hasAcceptedTos: savedDraft?.hasAcceptedTos || !!charity.createdAt,
      operatesInState: true,
      abnBelongsToParent: !!charityWithDraft.organization.localGroupName,
      ...getAttachmentsFromExistingCharity(charity, attachments || []),
      ...getAttachmentsFromSavedDraft(savedDraft), // TODO: update this when backend can return staged attachments
      OrganizationLogo: savedDraft?.OrganizationLogo || {
        filename: charity.logoUrl,
        uploadedAt: charity.updatedAt
      }
    } as Partial<CharityUiFields>
  }

  return {
    operatesInState: savedDraft?.operatesInState || false,
    abnBelongsToParent: !!savedDraft?.charity?.organization?.localGroupName,
    ...getAttachmentsFromSavedDraft(savedDraft)
  } as CharityUiFields
}

function getAttachmentsFromExistingCharity(charity: Charity, attachments: Attachment[]) {
  return attachments?.reduce((acc, attachment) => {
    const documentType = attachment.type as keyof CharityUiFields

    return {
      ...acc,
      [documentType]: {
        type: attachment.type,
        contentType: attachment.contentType,
        filename: attachment.type,
        savedAt: attachment.savedAt || charity.createdAt,
        savedBy: attachment.savedById || charity.createdBy
      } as Attachment
    }
  }, {})
}

function getAttachmentsFromSavedDraft(savedDraft?: Draft) {
  return attachmentsTypes.reduce((acc, type) => {
    if (savedDraft && savedDraft[type as keyof Draft]) {
      return { ...acc, [type]: savedDraft[type as keyof Draft] }
    }

    return acc
  }, {})
}
