import { logError } from '@tomra/datadog-browser-logging'
import { addMonths, isAfter, isBefore, subMonths } from 'date-fns'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { CheckmarkIcon, CloseIcon, Datepicker, Label, Modal, TimeIcon } from '../../../../components/shared'
import { THEME } from '../../../../lib'
import { SIX_MONTHS_IN_DAYS, calculateDaysRunning, calculateMaxDaysLeftToExtend } from '../../../../lib/campaign'
import { extendCharityCampaign } from '../../../../services'

const { supportEmail } = THEME

type Props = {
  campaignWithStatistics: CampaignWithStatistics
}

export const ExtendCampaign: FunctionComponent<Props> = ({ campaignWithStatistics }) => {
  const { charityId, campaignId, status, startTime, endTime } = campaignWithStatistics.campaign

  const startDate = new Date(startTime)
  const endDate = new Date(endTime)
  const daysRunning = calculateDaysRunning(startDate, endDate)

  const minEndDate = addMonths(endDate, 1)
  const maxEndDate = addMonths(endDate, 6)

  const [showExtendDialog, setShowExtendDialog] = useState(false)
  const [extendRequestStatus, setExtendRequestStatus] = useState<RequestStatus | 'validationFailed'>('initial')
  const [newEndDate, setNewEndDate] = useState(minEndDate)
  const [maxDaysLeftToExtend, setMaxDaysLeftToExtend] = useState<number>(() =>
    calculateMaxDaysLeftToExtend(startDate, endDate)
  )
  const [showCustomize, setShowCustomize] = useState(false)
  const [error, setError] = useState('')

  const campaignEndDate = new Date(endTime)
  const oneMonthBeforeEndTime = subMonths(campaignEndDate, 1)
  const campaignExpiresSoon = isAfter(new Date(), oneMonthBeforeEndTime)
  const campaignHasNotEnded = isBefore(new Date(), campaignEndDate) && status === 'ACTIVE'

  const extendCampaign = async () => {
    try {
      setError('')
      setExtendRequestStatus('loading')
      await extendCharityCampaign(charityId, campaignId, newEndDate)
      setExtendRequestStatus('success')

      setTimeout(() => {
        setShowExtendDialog(false)
        window.location.reload()
      }, 2500)
    } catch (error: any) {
      if (error.status === 400) {
        setError(error.body.detail ? error.body.detail : 'Failed to update campaign')
        setExtendRequestStatus('initial')
      } else {
        logError(new Error('Failed to extend campaign'), error)
        setExtendRequestStatus('failed')
      }
    }
  }

  const extendBy6Months = () => {
    setNewEndDate(maxEndDate)
    extendCampaign()
  }

  useEffect(() => {
    const daysLeft = calculateMaxDaysLeftToExtend(startDate, endDate)
    setMaxDaysLeftToExtend(daysLeft)
  }, [])

  const renderExtendButton = campaignExpiresSoon && campaignHasNotEnded

  return renderExtendButton ? (
    <div className="row-start-1 lg:col-start-6 lg:col-span-1 flex justify-end">
      <button className="btn primary btn-sm" onClick={() => setShowExtendDialog(true)}>
        Extend
      </button>

      <Modal isOpen={showExtendDialog}>
        <div className="dialog">
          <button
            className="btn-icon absolute right-4 top-4 "
            aria-label="Close"
            onClick={() => setShowExtendDialog(false)}
          >
            <CloseIcon width="1rem" height="1rem" />
          </button>
          <div className="dialog-content">
            {extendRequestStatus === 'success' ? (
              <div className="flex flex-col items-center justify-center space-y-6">
                <CheckmarkIcon width="4rem" height="4rem" color="rgb(var(--tomra-mint-green-dark))" />
                <h1 className="text-3xl text-center">Your campaign has been successfully updated</h1>
                <button
                  className="btn"
                  onClick={() => {
                    setShowExtendDialog(false)
                    setShowCustomize(false)
                  }}
                >
                  Ok
                </button>
              </div>
            ) : (
              <>
                <div className="flex flex-col items-center justify-center space-y-6">
                  <TimeIcon width="4rem" height="4rem" color="rgb(var(--tomra-mint-green-dark))" />

                  <h1 className="text-3xl mb-4">Extend your campaign</h1>
                  <p>Any issues or queries, please contact {supportEmail}.</p>
                </div>

                <div className="flex flex-col items-center justify-center">
                  {extendRequestStatus === 'loading' ? (
                    <div className="self-center loading-infinity w-20" />
                  ) : showCustomize ? (
                    <>
                      <div className="input-field w-full">
                        <Label htmlFor="datepicker">Set new end date</Label>
                        <Datepicker
                          id="datepicker"
                          className="input-text"
                          value={newEndDate}
                          onChange={date => {
                            if (date) {
                              setNewEndDate(date)
                            }
                          }}
                          min={minEndDate}
                          max={maxEndDate}
                          required
                        />
                      </div>
                      <div className="flex items-center justify-end space-x-4 w-full mt-4">
                        <button
                          className="btn"
                          onClick={() => {
                            setShowExtendDialog(false)
                            setShowCustomize(false)
                          }}
                        >
                          Cancel
                        </button>

                        <button className="btn primary" onClick={extendCampaign}>
                          Update
                        </button>
                      </div>
                    </>
                  ) : maxDaysLeftToExtend > SIX_MONTHS_IN_DAYS ? (
                    <div className="flex items-center justify-center w-full space-x-4">
                      <button className="btn" onClick={() => setShowCustomize(true)}>
                        Customize date
                      </button>

                      <button className="btn primary" onClick={extendBy6Months}>
                        Extend 6 months
                      </button>
                    </div>
                  ) : (
                    <>
                      <div className="alert info mb-6">
                        Your campaign has been running for {daysRunning} days.
                        <br />
                        You can extend it by an additional {maxDaysLeftToExtend} days.
                      </div>

                      <div className="flex items-center justify-center w-full space-x-4">
                        <button className="btn" onClick={() => setShowCustomize(true)}>
                          Customize date
                        </button>

                        <button className="btn primary" onClick={extendBy6Months}>
                          Extend {maxDaysLeftToExtend} days
                        </button>
                      </div>
                    </>
                  )}

                  {extendRequestStatus === 'failed' && (
                    <div className="alert danger mt-4">
                      <p>Something went wrong. Please try again.</p>
                      <p>If the issue persists, please contact us at {supportEmail}.</p>
                    </div>
                  )}

                  {error && <p className="alert warning mt-4">{error}</p>}
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    </div>
  ) : null
}
