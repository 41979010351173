import { HttpError } from '@tomra/client-side-http-client'
import { API_HOST, authenticatedFetchData } from '../lib'

export async function fetchCampaigns(charityId: string) {
  return await authenticatedFetchData(`${API_HOST}/charities/v1/charity/${charityId}/campaign`).run()
}

export function fetchCampaignById(charityId: string, campaignId: string) {
  return authenticatedFetchData(`${API_HOST}/charities/v1/charity/${charityId}/campaign/${campaignId}`).run()
}

export async function fetchPendingChangesForCampaign(charityId: string, campaignId: string) {
  try {
    return await authenticatedFetchData(
      `${API_HOST}/charities/v1/charity/${charityId}/pending-campaigns/${campaignId}`
    ).run()
  } catch (error) {
    // 404 means no changes
    if ((error as HttpError).status !== 404) {
      throw error
    }
  }
}

export async function submitCampaign(charityId: string, campaign: Campaign) {
  return authenticatedFetchData(`${API_HOST}/charities/v1/charity/${charityId}/campaign`, {
    method: 'POST',
    body: JSON.stringify({
      ...campaign,
      charityId,
      financialTarget: String(campaign.financialTarget).replace(/\D/g, ''),
      startTime: new Date(campaign.startTime).toISOString(),
      endTime: new Date(campaign.endTime).toISOString()
    })
  }).run()
}

export async function updateCampaign(charityId: string, campaign: Campaign) {
  return authenticatedFetchData(`${API_HOST}/charities/v1/charity/${charityId}/campaign/${campaign.campaignId}`, {
    method: 'PATCH',
    body: JSON.stringify({
      ...campaign,
      financialTarget: String(campaign.financialTarget).replace(/\D/g, ''),
      startTime: new Date(campaign.startTime).toISOString(),
      endTime: new Date(campaign.endTime).toISOString()
    })
  }).run()
}

export async function extendCharityCampaign(charityId: string, campaignId: string, newEndDate: Date) {
  return authenticatedFetchData(
    `${API_HOST}/charities/v1/charity/${charityId}/campaign/${campaignId}/extend/${encodeURIComponent(
      newEndDate.toISOString()
    )}`,
    {
      method: 'PUT'
    }
  ).run()
}

export function findLatestCampaign(campaignsWithStatistics: CampaignWithStatistics[]) {
  if (campaignsWithStatistics.length === 0) {
    return null
  }

  return campaignsWithStatistics.reduce(
    (acc, campaignWithStatistics) => {
      if (!acc) {
        return campaignWithStatistics
      }

      if (new Date(campaignWithStatistics.campaign.createdAt) > new Date(acc.campaign.createdAt)) {
        return campaignWithStatistics
      }

      return acc
    },
    null as CampaignWithStatistics | null
  )
}
