import { logError } from '@tomra/datadog-browser-logging'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { AlertIcon, EditIcon, VerifiedIcon } from '../../../../components/shared'
import { Image } from '../../../../components/shared/Image'
import { benefitToReadableString } from '../../../../components/shared/forms/enumsToReadableStrings'
import { fetchCharity, fetchPendingChangesForCharity } from '../../../../services'

type Props = {
  charitySummary: CharitySummary | null
}

export const CharityCard: FunctionComponent<Props> = ({ charitySummary }) => {
  const [hasPendingChanges, setHasPendingChanges] = useState<'tbd' | 'yes' | 'no'>('tbd')
  const [benefit, setBenefit] = useState('tbd')
  const [charityError, setCharityError] = useState('')
  const [pendingChangesError, setPendingChangesError] = useState('')

  useEffect(() => {
    if (!charitySummary) {
      return
    }

    fetchCharity(charitySummary.id)
      .then(({ charity }) => {
        setBenefit(benefitToReadableString[charity.organization.benefit])
      })
      .catch(error => {
        setCharityError('Failed to fetch charity benefit')
        logError(new Error('Failed to fetch charity'), error)
      })

    fetchPendingChangesForCharity(charitySummary.id)
      .then(data => {
        setHasPendingChanges(data ? 'yes' : 'no')
      })
      .catch(error => {
        setPendingChangesError('Failed to fetch pending changes')
        logError(new Error('Failed to fetch pending changes for charity'), error)
      })
  }, [charitySummary])

  return (
    <div className="card p-4 grid grid-cols-6 gap-4 items-start">
      <div className="row-start-1 col-start-1 col-span-3 text-sm text-storm dark:text-rock">
        {charityError ? (
          <span className="flex items-center space-x-2">
            <AlertIcon width="1rem" height="1rem" color="rgb(var(--tomra-warm-red))" />
            <span>Failed to load benefit type</span>
          </span>
        ) : benefit === 'tbd' ? (
          <div className="skeleton-paragraph w-28 h-[1.25rem]" />
        ) : (
          benefit
        )}
      </div>
      <div className="row-start-1 col-start-6 col-span-1 flex justify-end">
        <Link to={`/charity/${charitySummary?.id}`} aria-label="Edit charity">
          <EditIcon width="1.5rem" height="1.5rem" />
        </Link>
      </div>

      <div className="row-start-2 row-span-3 col-start-1 col-span-2">
        {charitySummary ? (
          <Image imageUrl={charitySummary.logoUrl as string} altText={`${charitySummary.displayName} logo`} />
        ) : (
          <div className="skeleton-circle w-32 h-32" />
        )}
      </div>

      <div className="row-start-3 col-start-3 col-span-3 flex flex-col justify-center h-full">
        {charitySummary ? (
          <div className="text-md font-bold">{charitySummary.displayName}</div>
        ) : (
          <div className="skeleton-heading w-48 h-[1.5rem] mb-2" />
        )}

        {charitySummary ? (
          <div className="text-storm dark:text-rock">{charitySummary.organizationName}</div>
        ) : (
          <div className="skeleton-paragraph w-28 h-[1.5rem]" />
        )}
      </div>

      <div className="row-start-4 col-start-4 col-span-3 flex justify-end">
        {hasPendingChanges === 'tbd' ? (
          <span className="skeleton-paragraph w-28 h-[1.875rem]" />
        ) : hasPendingChanges === 'yes' ? (
          <span className="chip text-sm !bg-fire-orange-lighter !text-fire-orange-darker">
            Changes pending approval
          </span>
        ) : charitySummary?.status === 'NEW' ? (
          <span className="chip text-sm !bg-fire-orange-lighter !text-fire-orange-darker">Pending approval</span>
        ) : charitySummary?.status === 'ACTIVE' ? (
          <VerifiedIcon title="Approved" width="1.5rem" height="1.5rem" className="text-teal-dark" />
        ) : (
          <AlertIcon title="Disabled" width="1.5rem" height="1.5rem" className="text-warm-red-dark" />
        )}
      </div>

      {pendingChangesError && <div className="col-start-1 col-span-6 alert danger">{pendingChangesError}</div>}
    </div>
  )
}
