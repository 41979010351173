import React, { FunctionComponent, useEffect } from 'react'
import { ProgressBar } from '../../../../components/shared/ProgressBar'
import { currencyFormatter } from '../../../../lib/campaign'

type Props = {
  campaignWithStatistics: CampaignWithStatistics | null
}

export const CampaignProgress: FunctionComponent<Props> = ({ campaignWithStatistics }) => {
  if (!campaignWithStatistics) {
    return null
  }

  const { statistics, campaign } = campaignWithStatistics

  if (campaign.status === 'NEW') {
    return (
      <div className="row-start-2 col-span-7">
        <div className="text-sm">Goal</div>
        <div className="md:flex-1">{currencyFormatter.format(campaign.financialTarget)}</div>
      </div>
    )
  }

  return (
    <>
      <div className="row-start-3 col-span-7 flex justify-between">
        <div>{currencyFormatter.format(statistics.totalDonated)}</div>
        {currencyFormatter.format(campaign.financialTarget)}
      </div>

      <div className="row-start-2 col-span-7 pt-5">
        <ProgressBar current={statistics.totalDonated} total={campaign.financialTarget} />
      </div>
    </>
  )
}
