import { logError } from '@tomra/datadog-browser-logging'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ErrorCard } from '../../components/shared/ErrorCard'
import { fetchCampaigns, fetchCharitySummary, findLatestCampaign } from '../../services'
import { CampaignStatistics } from './common/CampaignStatistics'
import { CharityState } from './common/CharityState'
import { DisabledCharity } from './common/DisabledCharity'
import { CampaignCard } from './common/campaign/CampaignCard'
import { CharityCard } from './common/charity/CharityCard'

export const DashboardPage = () => {
  const navigate = useNavigate()

  const [charityRequestStatus, setCharityRequestStatus] = useState<RequestStatus>('loading')
  const [charitySummary, setCharitySummary] = useState<CharitySummary | null>(null)
  const [campaignsWithStatistics, setCampaignWithStatistics] = useState<CampaignWithStatistics[] | null>(null)
  const [latestCampaign, setLatestCampaign] = useState<CampaignWithStatistics | null>(null)

  async function fetchCharity() {
    try {
      const summary = await fetchCharitySummary()

      if (!summary) {
        return navigate('/charity')
      }

      setCharitySummary(summary)
      setCharityRequestStatus('loaded')

      if (summary.status === 'DISABLED') {
        return
      }

      await fetchCampaignData(summary.id)
    } catch (error) {
      logError(new Error('Failed to load charity and campaigns'), error as Error)
      setCharityRequestStatus('failed')
    }
  }

  async function fetchCampaignData(charityId: string) {
    try {
      const allCampaigns = await fetchCampaigns(charityId)
      setLatestCampaign(findLatestCampaign(allCampaigns))
      setCampaignWithStatistics(allCampaigns)
    } catch (error) {
      logError(new Error('Failed to load campaigns'), error as Error)
      setCharityRequestStatus('failed')
    }
  }

  useEffect(() => {
    fetchCharity()
  }, [])

  if (charityRequestStatus === 'failed') {
    return <ErrorCard />
  }

  return (
    <>
      <h1 className="sr-only">Charity Dashboard</h1>

      {charitySummary?.status === 'DISABLED' ? (
        <DisabledCharity />
      ) : (
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 lg:col-span-8 lg:row-span-1">
            <CampaignCard charitySummary={charitySummary} campaignWithStatistics={latestCampaign} />
          </div>

          <div className="lg:col-start-9 lg:col-span-4 lg:row-span-2">
            <CharityCard charitySummary={charitySummary} />
          </div>

          {campaignsWithStatistics && campaignsWithStatistics.length > 1 ? (
            <CampaignStatistics campaignsWithStatistics={campaignsWithStatistics} />
          ) : (
            charitySummary && (
              <CharityState
                charityId={charitySummary.id}
                charityStatus={charitySummary.status}
                latestCampaignStatus={latestCampaign?.campaign?.status}
              />
            )
          )}
        </div>
      )}
    </>
  )
}
