import { logError } from '@tomra/datadog-browser-logging'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { EditIcon, Modal } from '../../../../components/shared'
import { fetchPendingChangesForCampaign } from '../../../../services'
import { CampaignEnd } from './CampaignEnd'
import { CampaignProgress } from './CampaignProgress'
import { CampaignStart } from './CampaignStart'
import { ExtendCampaign } from './ExtendCampaign'

const campaignStatusMap: { [key: string]: string } = {
  NEW: 'Current campaign',
  ACTIVE: 'Current campaign',
  EXPIRED: 'Expired campaign',
  STOPPED: 'Stopped campaign'
}

type Props = {
  charitySummary: CharitySummary | null
  campaignWithStatistics: CampaignWithStatistics | null
}

export const CampaignCard: FunctionComponent<Props> = ({ charitySummary, campaignWithStatistics }) => {
  const [hasPendingChanges, setHasPendingChanges] = useState<'tbd' | 'yes' | 'no'>('tbd')
  const [showCreateOrCopy, setShowCreateOrCopy] = useState(false)
  const navigate = useNavigate()

  async function fetchPendingChanges(charityId: string, campaignId: string) {
    try {
      const data = await fetchPendingChangesForCampaign(charityId, campaignId)
      setHasPendingChanges(data ? 'yes' : 'no')
    } catch (error) {
      logError(new Error('Failed to load pending changes for campaign'), error as Error)
    }
  }

  useEffect(() => {
    if (!charitySummary || !campaignWithStatistics) {
      return
    }

    fetchPendingChanges(charitySummary.id, campaignWithStatistics.campaign.campaignId)
  }, [charitySummary, campaignWithStatistics])

  const showEditButton =
    campaignWithStatistics?.campaign?.status !== 'EXPIRED' && campaignWithStatistics?.campaign?.status !== 'STOPPED'
  const showCopyButton =
    campaignWithStatistics?.campaign?.status === 'EXPIRED' || campaignWithStatistics?.campaign?.status === 'STOPPED'

  return (
    <>
      <div className="card p-4 grid lg:grid-cols-7 gap-x-4 items-start">
        {showEditButton ? (
          <div className="row-start-1 lg:col-start-7 lg:col-span-1 flex justify-end">
            <Link
              to={`/charity/${charitySummary?.id}/campaign/${campaignWithStatistics?.campaign?.campaignId}`}
              aria-label="Edit campaign"
            >
              <EditIcon width="1.5rem" height="1.5rem" />
            </Link>
          </div>
        ) : showCopyButton ? (
          <div className="row-start-1 lg:col-start-7 lg:col-span-1 flex justify-end">
            <button className="btn primary btn-sm" onClick={() => setShowCreateOrCopy(true)}>
              Create new
            </button>
          </div>
        ) : null}

        {charitySummary && campaignWithStatistics && <ExtendCampaign campaignWithStatistics={campaignWithStatistics} />}

        <div className="row-start-1 col-start-1 col-span-2">
          {campaignWithStatistics ? (
            <div className="text-sm text-storm dark:text-rock">
              {campaignStatusMap[campaignWithStatistics.campaign.status]}
            </div>
          ) : (
            <div className="skeleton-paragraph w-10 h-[1.25rem]" />
          )}

          {campaignWithStatistics ? (
            <div className="text-xl">{campaignWithStatistics.campaign.name}</div>
          ) : (
            <div className="skeleton-heading w-20 h-[1.75rem] mt-1" />
          )}
        </div>

        <div className="row-start-1 col-start-4">
          <CampaignStart campaignWithStatistics={campaignWithStatistics} />
        </div>

        <div className="row-start-1 col-start-5">
          <CampaignEnd campaignWithStatistics={campaignWithStatistics} />
        </div>

        <CampaignProgress campaignWithStatistics={campaignWithStatistics} />

        {hasPendingChanges === 'tbd' ? (
          <div className="row-start-4 col-start-7 col-span-2 flex justify-end mt-4">
            <span className="skeleton-paragraph w-28 h-[1.875rem]" />
          </div>
        ) : hasPendingChanges === 'yes' ? (
          <div className="row-start-4 col-start-7 col-span-2 flex justify-end mt-4">
            <div className="chip text-sm !bg-fire-orange-lighter !text-fire-orange-darker">
              Changes pending approval
            </div>
          </div>
        ) : campaignWithStatistics && campaignWithStatistics.campaign.status === 'NEW' ? (
          <div className="row-start-4 col-start-7 col-span-2 flex justify-end mt-4">
            <div className="chip text-sm !bg-fire-orange-lighter !text-fire-orange-darker">Pending approval</div>
          </div>
        ) : null}
      </div>

      {charitySummary && campaignWithStatistics && (
        <Modal isOpen={showCreateOrCopy}>
          <div className="dialog">
            <div className="dialog-header">
              <h2>Copy previous campaign details?</h2>
            </div>
            <div className="dialog-footer">
              <button className="btn" onClick={() => navigate(`/charity/${charitySummary.id}/campaign`)}>
                No, create a brand new one
              </button>
              <button
                className="btn primary"
                onClick={() =>
                  navigate(`/charity/${charitySummary.id}/campaign?copy=${campaignWithStatistics.campaign.campaignId}`)
                }
              >
                Yes, copy previous campaign details
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}
