import { useReducer } from 'react'
import { initUiFields } from '../lib/uiFields'

type UiFieldAction =
  | {
      type: 'UPDATE_UI_FIELDS'
      payload: Partial<CharityUiFields>
    }
  | {
      type: 'UPDATE_ATTACHMENT_ERRORS'
      payload: AttachmentError[]
    }

const uiFieldsReducer = (state: CharityUiFields, { type, payload }: UiFieldAction): CharityUiFields => {
  switch (type) {
    case 'UPDATE_UI_FIELDS':
      return { ...state, ...payload }
    case 'UPDATE_ATTACHMENT_ERRORS':
      return { ...state, attachmentErrors: payload }
    default:
      throw new Error(`Unhandled action type: ${type}`)
  }
}

export const useUiFields = (original: Charity | null, savedDraft?: Draft, attachments?: Attachment[]) => {
  const initialState = initUiFields(original, savedDraft, attachments)

  const [state, dispatch] = useReducer(uiFieldsReducer, { ...initialState, attachmentErrors: [] } as CharityUiFields)

  function updateUiField(payload: Partial<CharityUiFields>) {
    dispatch({ type: 'UPDATE_UI_FIELDS', payload })
  }

  function updateAttachmentErrors(payload: AttachmentError[]) {
    dispatch({ type: 'UPDATE_ATTACHMENT_ERRORS', payload })
  }

  return {
    uiFieldsState: state,
    updateUiField,
    updateAttachmentErrors
  }
}
